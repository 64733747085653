import {
  CategoryType,
  IExperienceDetails,
} from "components/ExperienceDetails/types";

import { Moment } from "moment";
import { ReferralInputType } from "./checkout/components/referralInput";
import { SubscriptionType } from "./../../ExperienceDetails/types";

export type AccommodationPricingType = {
  accommodation_type: string;
  id: number;
  price: number;
};
export interface ICategory {
  id: number;
  name: string;
  slug: string;
  path: string | null;
  is_parent: boolean;
}
export enum CategoriesObject {
  TRAVEL = "travel",
  WORK_FROM_ANYWHERE = "work-from-anywhere",
  ONLINE_EXPERIENCES = "online-experiences",
  WEEKEND_TRIPS = "weekend-trips",
  LONG_TRIPS = "long-trips",
  YOGA = "yoga",
  BEAUTY_EXPERIENCES = "beauty-experiences",
  WELLNESS = "wellness",
  TTC = "teachers-training-courses",
}

export type SelectableCardType = {
  displayName: string;
  id: number;
  price?: number;
};

export type CheckoutBottomCardType = {
  originalPrice: number;
  selectedPrice: number;
  perEntityText: string;
  buttonText: string;
  onClick: () => void;
};

export type GetPricesType = {
  price: number;
  selectedPrice: number;
  duration: number;
  pax: number;
};

export type GetPricesReturnType = {
  originalPrice: number;
  computedPrice: number;
};

export interface ICategories {
  id: number;
  is_parent: boolean;
  name: string;
  path: string;
  slug: string;
}

export type UpcomingSlotType = {
  created: number;
  end: number;
  id: number;
  is_active: boolean;
  is_deleted: boolean;
  package_id: number;
  start: number;
  updated: number;
};

export type UpcomingTimeSlotType = {
  end_at: number;
  id: number;
  is_active: boolean;
  start_at: number;
};

export type PricingCategoryType = {
  accommodation_type: string;
  created: string;
  id: number;
  individual_pricing: unknown;
  is_active: boolean;
  is_deleted: boolean;
  is_private: boolean;
  max_guests: number;
  package_id: number;
  pax: number | null;
  price: number;
  updated: number;
};

export type UpcomingSlots = UpcomingSlotType[];
export type PricingCategories = PricingCategoryType[];
export type SubscriptionCard = {
  id: number;
  name: string;
  discount_price: number;
  duration: number;
};

export type SubscriptionCards = SubscriptionCard[];

export interface ICheckout {
  show: boolean;
  pax: number;
  updatePax: (pax: number) => void;
}

export interface SubscriptionCheckoutType extends ICheckout {
  onSelectCard: (id: number) => void;
  subscriptionPlans: SubscriptionCards;
  selectedSubscriptionCard: SubscriptionType;
  onSelectSlot: (key: string, id: number) => void;
  onSelectTimeSlot: (key: string, id: number) => void;
  upcomingSlots: SelectableCardType[];
  upcomingTimeSlots: SelectableCardType[];
  selectedTimeSlot: number;
  accommodationPricing: AccommodationPricingType[];
  pricingCategories: SelectableCardType[];
  selectedPriceId: number;
  onSelectPricingCategory: (key: string, id: number) => void;
  selectedSlot: number;
  categories: CategoryType[];
}

export type SubscriptionCardType = {
  subscriptionPlans: SubscriptionCards;
  onClick: (id: number) => void;
  selectedSubscriptionCard: SubscriptionType;
  categories: CategoryType[];
};

export interface IUpcomingSlots {
  upcomingSlots: SelectableCardType[];
  selectedSlotId: number;
  onSelect: (key: string, id: number) => void;
  stateKey: string;
}

export type GenerateSlotReturnType = SelectableCardType[];
export interface IPricingCategories {
  pricingCategories: SelectableCardType[];
  selectedPriceId: number;
  onSelect: (key: string, id: number) => void;
  categories: CategoryType[];
}

export interface ITravelCheckout extends ICheckout {
  pricingCategories: SelectableCardType[];
  selectedPriceId: number;
  onSelectPricingCategory: (key: string, id: number) => void;
  selectedSlot: number;
  onSelectSlot: (key: string, id: number) => void;
  upcomingSlots: SelectableCardType[];
  categories: CategoryType[];
}

type DateRangeType = [Moment, Moment];
export interface IStaysCheckout extends ICheckout {
  pricingCategories: SelectableCardType[];
  selectedPriceId: number;
  onSelectPricingCategory: (key: string, id: number) => void;
  onDateChange: (values: DateRangeType, formatString: [string, string]) => void;
  categories: CategoryType[];
}

export interface SideBardDataType {
  experienceDetails: IExperienceDetails;
  start_date: string;
  end_date: string;
  duration: number;
  accommodation_id: number;
  upcoming_trip_id: number;
  privatePax: number;
  pax: number;
  upcomingSlots: SelectableCardType[];
  selectedSubscriptionCard: SubscriptionCard;
  referralInputProps: ReferralInputType;
}

export type DefaultConfirmPayPropsType = {
  sidebarData: SideBardDataType;
  createOrder: () => void;
};
