import { FC, memo } from "react";

import { IExperienceDetails } from "components/ExperienceDetails/types";
import handleCheckoutVisibility from "components/checkout/v2/services/handleCheckoutVisibility";
import { CategoriesObject } from "components/checkout/v2/types";
import ExperienceCardBody from "./experienceCardBody";
import ExperienceCardHeader from "./experienceCardHeader";
import RibbonBadgeHoc from "./ribbonBadgeHoc";

interface ExperienceCardProps {
  package: IExperienceDetails;
  className?: string;
  whiteText?: boolean;
}

const ExperienceCard: FC<ExperienceCardProps> = ({
  package: item,
  className,
  whiteText,
}) => {
  const isFifteenDays = item?.subscription_plans?.find(
    (plans) => plans?.duration === 0.5
  );

  if (
    isFifteenDays ||
    handleCheckoutVisibility(item.categories, [
      CategoriesObject.TRAVEL,
      CategoriesObject.TTC,
    ])
  )
    return (
      <RibbonBadgeHoc
        text={
          isFifteenDays
            ? "Trial"
            : handleCheckoutVisibility(item.categories, [
                CategoriesObject.TRAVEL,
                CategoriesObject.TTC,
              ])
            ? "No Cost EMI"
            : ""
        }
      >
        <div className={`card h-100 packageCard ${className}`}>
          <ExperienceCardHeader item={item} />
          <ExperienceCardBody item={item} whiteText={whiteText} />
        </div>
      </RibbonBadgeHoc>
    );
  return (
    <div className={`card h-100 packageCard ${className}`}>
      <ExperienceCardHeader item={item} />
      <ExperienceCardBody item={item} whiteText={whiteText} />
    </div>
  );
};

export default memo(ExperienceCard);
