import { Badge } from "antd";
import { ReactNode } from "react";
type RibbonBadgeHocProps = {
  children: ReactNode;
  text: string;
};
const RibbonBadgeHoc = (props: RibbonBadgeHocProps) => (
  <Badge.Ribbon color={"#00ba8c"} text={props.text} placement="start">
    {props.children}
  </Badge.Ribbon>
);

export default RibbonBadgeHoc;
