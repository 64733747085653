import { ReactNode } from "react";

type ExperienceBadge = {
  label: ReactNode;
  bgColor: string;
};

type ExperienceCardBadgesProps = {
  badges: ExperienceBadge[];
};

const ExperienceCardBadges = ({ badges }: ExperienceCardBadgesProps) => {
  if (!badges.some((badge) => badge.label)) return null;
  return (
    <div className="d-flex gap-1 flex-wrap">
      {badges.map((badge) => {
        if (!badge) return null;
        return (
          <div
            key={badge.bgColor}
            className={`fs-10 px-2 py-0.5 fw-500 ${badge.bgColor} rounded-3`}
          >
            <span>{badge.label}</span>
          </div>
        );
      })}
    </div>
  );
};
export default ExperienceCardBadges;
