import { Fragment, memo } from "react";

import Analytics from "utils/Analytics";
import GooglePlayStoreButton from "common/buttons/GooglePlayStoreButton";
import Link from "next/link";
import Logo from "common/text/Logo";
import MediaPublication from "common/AsFeaturedInStrip/mediaPublication";
import SocialMediaStrip from "common/social/SocialMediaStrip";
import Utils from "utils/Utils";
import { useCommonProvider } from "services/contextProviders/CommonProvider";

const Footer = () => {
  const { locations, categories } = useCommonProvider();

  const listItem = (text, path) => (
    <p>
      <Link href={path}>
        <a
          className={"fw-500 text-white fs-15"}
          onClick={() => Analytics.track(`${text}_footer`, { path: path })}
        >
          {text}
        </a>
      </Link>
    </p>
  );

  const footerLinks = (label, arr, path) => {
    if (!Utils.arrayCheck(arr)?.length) return null;
    return (
      <div className="d-flex flex-row flex-wrap">
        <div>
          <p className={"fs-14 text-white lh-30 mb-0"}>{label} &nbsp;</p>
        </div>
        {arr.map(({ name, slug }, index) => (
          <Fragment key={name}>
            <Link href={`${path}/${slug}`}>
              <a className={"primaryText fw-500 fs-14 lh-30"}>{name}</a>
            </Link>
            <span className="text-primary" style={{ lineHeight: 2 }}>
              &nbsp;{arr.length - 1 !== index && "|"}&nbsp;
            </span>
          </Fragment>
        ))}
      </div>
    );
  };

  const getPath = (item: any) => {
    if (item.is_parent || !Utils.arrayCheck(item?.parent_ids).length)
      return item.slug;
    return `${categories.find((c) => c.id === item.parent_ids[0]).slug}/${
      item.slug
    }`;
  };

  const footerLinksCategories = (label, dict, base) => {
    if (!Utils.arrayCheck(dict)?.length) return null;
    return (
      <div className="d-flex flex-row flex-wrap">
        <div>
          <p className={"fs-14 text-white lh-30 mb-0"}>{label} &nbsp;</p>
        </div>
        {dict.map(({ name, ...it }, index) => (
          <Fragment key={name}>
            <Link href={`${base}/${getPath(it)}`}>
              <a className={"primaryText fw-500 fs-14 lh-30"}>{name}</a>
            </Link>
            <span className="text-primary" style={{ lineHeight: 2 }}>
              &nbsp;{dict.length - 1 !== index && "|"}&nbsp;
            </span>
          </Fragment>
        ))}
      </div>
    );
  };

  return (
    <>
      <MediaPublication />
      <footer className="bg-dark pt-5 pb-2">
        <div className="container-md">
          <div className="row">
            <div className="col-md-3 mb-3">
              <Logo textWhite />
              <div className="my-4" />
              <p className="text-white fw-500 mb-0">Download our App</p>
              <GooglePlayStoreButton height={70} />
            </div>
            <div className="col-md-3">
              <h6 className={"text-white mb-4"}>Check'em out</h6>
              {listItem("Our Blog", "/blog")}
              {listItem("About", "/about")}
              {listItem("FAQs", "/faqs")}
              {listItem(
                "Terms, Payment & Cancellation Policy",
                "/terms-and-more"
              )}
              {listItem("Privacy Policy", "/privacy-policy")}
              <p>
                <a
                  onClick={() => Analytics.track("partner_with_us")}
                  rel="noopener noreferrer"
                  className={"fw-500 text-white fs-15"}
                  href={"https://bookweekendtours.typeform.com/to/i3sEVo"}
                  target={"_blank"}
                >
                  Partner with us
                </a>
              </p>

              <p>
                <a
                  onClick={() => Analytics.track("jobs")}
                  rel="noopener noreferrer"
                  className={"fw-500 text-white fs-14"}
                  href={"https://angel.co/company/book-weekend-tours-1/jobs"}
                  target={"_blank"}
                >
                  We're hiring{" "}
                  <span aria-label="hiring" role="img">
                    🚀
                  </span>
                </a>
              </p>
            </div>
            <div className="col-md-3 mb-3">
              <h6 className={"text-white mb-4"}>Follow us on</h6>

              <SocialMediaStrip />
              <p className="mt-4">
                <a
                  onClick={() => Analytics.track("join_community")}
                  rel="noopener noreferrer"
                  className={"fw-500 text-white fs-15"}
                  href={"https://club.bwtexperiences.com/"}
                  target={"_blank"}
                >
                  Join Our Community
                </a>
              </p>
            </div>
            <div className="col-md-3 mb-3">
              <p className="fw-500 fs-14 text-white">Gurugram, India</p>

              <p>
                <a
                  onClick={() =>
                    Analytics.track("mail_to_hello_bwtexperiences")
                  }
                  className="primaryText fs-14 fw-500 text-break"
                  href="mailto:hello@bwtexperiences.com"
                >
                  hello@bwtexperiences.com
                </a>
              </p>
              <p className="text-white fs-12 mb-0">Helpline Number</p>
              <p className="text-white fw-500">1800-121-0058</p>
            </div>
          </div>
        </div>

        <hr />

        <div className="container">
          <div className={"d-flex mb-3 fw-500"}>
            {footerLinks(
              "Discover Locations:",
              locations,
              "/discover/locations"
            )}
          </div>
          <div className={"d-flex fw-500"}>
            {footerLinksCategories(
              "Discover Categories:",
              categories,
              `/discover`
            )}
          </div>
        </div>
        <hr />

        <p className="fw-500 fs-14 text-center text-white mt-4 mb-0">
          © BookWell Technologies LLP - {new Date().getFullYear()} | All Rights
          Reserved
        </p>
      </footer>
    </>
  );
};

export default memo(Footer);
