import { ImageType } from "components/ExperienceDetails/types";
import Utils from "utils/Utils";

type ExperienceCardImageProps = {
  thumbnail: ImageType;
  title: string;
};

const ExperienceCardImage = ({
  thumbnail,
  title,
}: ExperienceCardImageProps) => {
  if (!thumbnail) return <></>;
  let original = thumbnail.urls.original;
  // replace bookweekendtours with bwtexperiences
  original = original.replace("bookweekendtours", "bwtexperiences");

  return (
    <picture>
      <source
        className={"img-fluid packageImg shadow"}
        data-srcset={Utils.constructImageUrl("400x300", "webp", original)}
        type="image/webp"
      />
      <source
        className={"img-fluid packageImg shadow"}
        data-srcset={Utils.constructImageUrl("400x300", "jpeg", original)}
        type="image/jpeg"
      />
      <img
        loading={"lazy"}
        className={"img-fluid packageImg shadow"}
        src={Utils.constructImageUrl("400x300", "jpeg", original)}
        alt={title}
        title={title}
      />
    </picture>
  );
};
export default ExperienceCardImage;
