import Analytics from "utils/Analytics";
import ExperienceCardImage from "./ExperienceCardImage";
import { IExperienceDetails } from "components/ExperienceDetails/types";
import Link from "next/link";
import Utils from "utils/Utils";
import styles from "./ExperienceCard.module.scss";
type ExperienceCardHeaderProps = {
  item: IExperienceDetails;
};
const ExperienceCardHeader = (props: ExperienceCardHeaderProps) => {
  const { item } = props;
  const thumbnail = item.thumbnail_image || item.images[0];
  return (
    <div className="card-header">
      <Link href={`/experiences/${item.id}/${Utils.slugString(item.title)}`}>
        <a
          onClick={() =>
            Analytics.track("package_card_image", {
              title: item.title,
              id: item.id,
            })
          }
        >
          <ExperienceCardImage thumbnail={thumbnail} title={item.title} />
        </a>
      </Link>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div style={{ top: "8px", right: "8px" }} className="position-absolute">
          {!!item?.duration && (
            <div className={styles.cardTag}>
              {item?.duration} {`${item?.duration}` === "1" ? "Day" : "Days"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExperienceCardHeader;
