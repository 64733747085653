import Analytics from "utils/Analytics";
import Utils from "utils/Utils";

const MediaPublication = () => (
  <div className="container-fluid">
    <div
      style={{
        backgroundImage: `url(https://cdn.bwtexperiences.com/image-bank/media_cover_orig.png)`,
      }}
      className="row p-4 align-items-end"
    >
      <div className="col-12">
        <h1 className="text-center fw-600 mb-4">As shown in</h1>
      </div>
      <div className="col-md-4 mb-sm-cm">
        <img
          className="img-fluid mx-auto d-block"
          height={300}
          width={300}
          src={Utils.cdnUrl("image-bank/the-better-india_orig.png")}
          alt="The Better India"
        />
        <div className="d-flex align-items-center justify-content-center m-4">
          <a
            onClick={() => {
              return Analytics.track("better_india");
            }}
            style={{
              boxShadow: "8px 8px 1px 0px rgba(0,0,0,1)",
            }}
            rel="noopener noreferrer"
            className={
              "fw-500 fs-16 px-5 callToAction bg-white text-dark border border-dark"
            }
            href={
              "https://www.thebetterindia.com/277507/workcation-stress-buster-vacation-work-remotely-benefits-wfh/"
            }
            target={"_blank"}
          >
            Featured By The Better India
          </a>
        </div>
      </div>
      <div className="col-md-4 mb-sm-cm">
        <img
          className="img-fluid mx-auto d-block"
          height={350}
          width={350}
          src={Utils.cdnUrl("image-bank/yourstory-logo_orig.png")}
          alt="Your Story"
        />
        <div className="d-flex align-items-center justify-content-center m-4">
          <a
            onClick={() => {
              return Analytics.track("your_story_click");
            }}
            style={{
              boxShadow: "8px 8px 1px 0px rgba(0,0,0,1)",
            }}
            rel="noopener noreferrer"
            className={
              "fw-500 fs-16 px-5 callToAction bg-white text-dark border border-dark"
            }
            href={"https://yourstory.com/companies/bwt-experiences/amp"}
            target={"_blank"}
          >
            Check our Profile
          </a>
        </div>
      </div>
      <div className="col-md-4">
        <img
          className="img-fluid mx-auto d-block p-4"
          height={180}
          width={180}
          src={Utils.cdnUrl("image-bank/new-bwt-logo_orig.png")}
          alt="Your Story"
        />
        <div className="d-flex align-items-center justify-content-center m-4">
          <a
            onClick={() => {
              return Analytics.track("join_community");
            }}
            style={{
              boxShadow: "8px 8px 1px 0px rgba(0,0,0,1)",
            }}
            rel="noopener noreferrer"
            className={
              "fw-500 fs-16 px-5 callToAction bg-white text-dark border border-dark"
            }
            href={"https://club.bwtexperiences.com/"}
            target={"_blank"}
          >
            Join Our Community
          </a>
        </div>
      </div>
    </div>
  </div>
);
export default MediaPublication;
