import { FC, memo } from "react";

const GooglePlayStoreButton: FC<{ height?: number }> = ({
  height = 70,
  ...props
}) => (
  <a
    {...props}
    target={"_blank"}
    rel="noopener noreferrer"
    className="ms-n2"
    href="https://play.google.com/store/apps/details?id=com.bwtexperiences.bwt&utm_source=web&utm_campaign=android_app_download&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
  >
    <img
      height={height}
      alt="Get it on Google Play"
      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
    />
  </a>
);

export default memo(GooglePlayStoreButton);
