import { createContext, useEffect, useContext, FC, ComponentType } from 'react'

const CommonContext = createContext({});

export interface CommonProviderProps {
    locations: Array<any>,
    categories: Array<any>,
    config: Array<any>
}

const CommonProvider: FC<CommonProviderProps> = ({locations, categories, config, children}) => {

    const context = {
        locations,
        categories,
        config
    }

    return (
        <CommonContext.Provider value={context}>
            {children}
        </CommonContext.Provider>
    )
}

export function useCommonProvider(): CommonProviderProps {
    return useContext(CommonContext) as CommonProviderProps
}

export default CommonProvider