import { CategoriesObject } from "components/checkout/v2/types";
import { CategoryType } from "components/ExperienceDetails/types";
import handleCheckoutVisibility from "components/checkout/v2/services/handleCheckoutVisibility";

type ExperienceSubText = {
  textWithPrice: string;
  label: string;
};

export const getExperienceSubText = (
  categories: CategoryType[]
): ExperienceSubText => {
  if (
    handleCheckoutVisibility(categories, [
      CategoriesObject.YOGA,
      CategoriesObject.WELLNESS,
    ])
  )
    return { textWithPrice: "/month", label: "month" };
  else if (
    handleCheckoutVisibility(categories, [CategoriesObject.WORK_FROM_ANYWHERE])
  )
    return { textWithPrice: "/night", label: "night" };
  else if (handleCheckoutVisibility(categories, [CategoriesObject.TRAVEL]))
    return { textWithPrice: "/person", label: "person" };
  else if (
    handleCheckoutVisibility(categories, [CategoriesObject.ONLINE_EXPERIENCES])
  )
    return { textWithPrice: "/session", label: "session" };
};
