import Analytics from "utils/Analytics";
import ExperienceCardBadges from "./ExperienceCardBadges";
import { IExperienceDetails } from "components/ExperienceDetails/types";
import Link from "next/link";
import Utils from "utils/Utils";
import { getExperienceSubText } from "utils/getExperienceSubText";

type ExperienceCardBodyProps = {
  item: IExperienceDetails;
  whiteText?: boolean;
};
const ExperienceCardBody = (props: ExperienceCardBodyProps) => {
  const { item, whiteText } = props;
  const checkDayTime = item?.time_slot?.toLowerCase();

  const badges = [
    {
      label: checkDayTime?.includes("am")
        ? "Morning"
        : checkDayTime?.includes("pm")
        ? "Evening"
        : "",
      bgColor: checkDayTime?.includes("am")
        ? "bg-warning border border-dark"
        : checkDayTime?.includes("pm")
        ? "bg-success border border-dark text-white"
        : "",
    },
    {
      label: item.time_slot,
      bgColor: "border border-primary",
    },
  ];
  return (
    <div className="card-body p-0 pt-2 d-flex flex-column mt-2">
      <div className="d-flex flex-row align-items-start justify-content-between mb-1">
        <div>
          <p className={"fw-600 fs-16 mb-1"}>
            <Link
              href={`/experiences/${item.id}/${Utils.slugString(item.title)}`}
            >
              <a
                onClick={() =>
                  Analytics.track("package_card_title", {
                    title: item.title,
                    id: item.id,
                  })
                }
                className={whiteText ? "text-white" : "text-dark"}
              >
                {item?.short_title}
              </a>
            </Link>
          </p>
        </div>
        <div>
          <div className={"mb-0 d-flex align-items-center"}>
            <img
              height={15}
              width={15}
              src={Utils.cdnUrl(`webImages/icons/star.svg`)}
              alt={"rating"}
            />
            <span className={"fs-12 fw-600 align-self-center"}>
              {item.vendor.rating}&nbsp;{" "}
            </span>
          </div>
        </div>
      </div>

      <div className="mt-auto">
        <p className={"mb-1"}>
          {item?.price > item.accommodation_pricing[0]?.price && (
            <span className="text-danger fw-600">
              -
              {
                // get percentage discount
                Math.round(
                  ((item.price - item.accommodation_pricing[0].price) /
                    item.price) *
                    100
                )
              }
              %
            </span>
          )}
          &nbsp;
          <span className="fs-16 fw-600">
            {Utils.convertToIndianCurrency(
              item?.accommodation_pricing[0]?.price
            )}
          </span>
          <span className={"fw-400"}>
            {getExperienceSubText(item.categories).textWithPrice}
          </span>
        </p>
        <p>
          {item.price && item.price > item?.accommodation_pricing[0]?.price && (
            <span className="fs-12 fw-500">
              Original Price:{" "}
              <del>{Utils.convertToIndianCurrency(item.price)}</del>&nbsp;
            </span>
          )}
        </p>
      </div>
      <div className="my-2">
        <ExperienceCardBadges badges={badges} />
      </div>
    </div>
  );
};

export default ExperienceCardBody;
