import { FC, memo } from "react";

import Utils from "utils/Utils";

interface LogoProps {
  textWhite?: boolean;
}

const Logo: FC<LogoProps> = ({ textWhite }) => (
  <h1 className={"fs-18 fw-600 mb-0"}>
    <img
      className="brandImg"
      height={40}
      alt="BWT Experiences Logo"
      src={Utils.cdnUrl("image-bank/new-bwt-logo_orig.png")}
    />
    &nbsp;&nbsp;
    <span className={textWhite && "text-white"}>experiences</span>
  </h1>
);

export default memo(Logo);
