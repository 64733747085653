import { FC, memo } from 'react';
import { Facebook, Instagram, Twitter } from 'common/Icons/Icons';
import Analytics from 'utils/Analytics';

const SocialMediaStrip: FC<{ center?: string, className?: string }> = ({ center, className = '' }) =>
	<div className={`d-flex flex-wrap align-items-center justify-content-${center ? 'center' : 'start'} ${className}`}>
		<div>
			<a onClick={() => Analytics.track('social_strip', { social_channel: 'facebook' })}
				rel="noopener noreferrer" title="Facebook" href="https://www.facebook.com/bwtexperiences" target="blank">
				<Facebook fill={'#1ab277'} />
			</a>
		</div>
		<div className="px-2" />
		<div>
			<a onClick={() => Analytics.track('social_strip', { social_channel: 'instagram' })}
				rel="noopener noreferrer" title="Instagram" href="https://www.instagram.com/bwtexperiences" target="blank">
				<Instagram fill={'#1ab277'} />
			</a>
		</div>
		<div className="px-2" />
		<div>
			<a onClick={() => Analytics.track('social_strip', { social_channel: 'twitter' })}
				rel="noopener noreferrer" title="Twitter" href="https://twitter.com/bwtexperiences" target="blank">
				<Twitter fill={'#1ab277'} />
			</a>
		</div>
	</div>;

export default memo(SocialMediaStrip)