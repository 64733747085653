import { CategoryType } from "components/ExperienceDetails/types";

const handleCheckoutVisibility = (
  categories: CategoryType[],
  categoriesToCheck: string[]
) => {
  return categories?.some((it: CategoryType) =>
    categoriesToCheck?.includes(it.slug)
  );
};

export default handleCheckoutVisibility;
